.wrapper-wide {
    padding: 1.7777777778em 2em 2.7777777778em;
    @media (min-width: 830px) {
        padding: 1.7777777778em 3.8888888889em 2.7777777778em;
    }
    @media (min-width: 1700px) {
        max-width: 1440px;
        margin: 0 auto;
    }
    h1 {
        font-size: 1.33333333333333em;
        font-weight: 700;
        line-height: 0.875;
        margin-bottom: 1.208333333333333em;
    }
    p {
        font-size: 0.68376em;
        line-height: 1.3125;
        font-weight: 400;
    }
}