.header-section {
  
  .main-nav {
    // margin-top: 2.5em;
    padding: 1.777777777777778em 2em 2.777777777777778em;
    color: var(--main-font);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    @media (min-width: 830px) {
      padding: 1.777777777777778em 3.88888888888888em 2.777777777777778em;
    }

    nav {
      ul {
        margin: 0;
        list-style: none;
        padding: 0;
        a {
          color: var(--main-font);
          text-decoration: none;
          font-size: 0.8888888888888889em;
          font-weight: 400;
          transition: all 0.4s ease-in-out;
          &:hover {
            text-shadow: 4px 4px 6px #f3f9ff47;
          }
        }
      }
    }

    .logo {
      text-align: center;
      img {
        width: 7em;
        height: auto;
      }
    }

    .header-btns {
      text-align: right;
      button {
        font-size: 1em;
        line-height: 1.33333333333333;
        border: 2px solid var(--button-bg-col);
        background-color: transparent;
        color: var(--main-font);
        border-radius: 0.5555555555555556em;
        padding: 0.2504444444em 1.776987em;
        font-family: "Philosopher", sans-serif;
        box-shadow: 0px 4px 4px 0px #00000040;
        transition: all 0.4s ease-in-out;
        font-weight: 700;
        cursor: pointer;
        &.register-btn {
          background-color: var(--button-bg-col);
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 25%);
          &:hover {
            background-color: transparent;
          }
        }
        &.login-btn {
          &:hover {
            background-color: var(--button-bg-col);
          }
        }
      }
    }

    &.desktop-nav {
      @media (max-width: 830px) {
        display: none;
      }

      & > * {
        flex: 1;
      }
      @media (min-width: 1700px) {
        max-width: 1440px;
        margin: 0 auto;
      }

      nav {
        ul {
          display: flex;
          justify-content: space-between;
          max-width: 19.8222222em;
        }
      }

      .header-btns {
        button {
          &:first-of-type {
            margin-right: 1.222222222222222em;
          }
        }
      }
    }

    &.mobile-nav {
      @media (min-width: 831px) {
        display: none;
      }

      .mobile-menu-btn {
        width: 31px;
        height: 19px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .mobile-menu-btn::before,
      .mobile-menu-btn::after,
      .mobile-menu-btn div {
        background: #fff;
        content: "";
        display: block;
        height: 2px;
        border-radius: 100px;
        // margin: 0.4615384615384615em 0;
        transition: 0.5s;
      }

      .mobile-menu-btn.opened:before {
        transform: translateY(5px) rotate(135deg);
      }
      .mobile-menu-btn.opened:after {
        transform: translateY(-12px) rotate(-135deg);
      }
      .mobile-menu-btn.opened div {
        transform: scale(0);
      }

      nav {
        position: absolute;
        width: calc(100vw - 4rem);
        // min-height: calc(100vh - 9em);
        top: 9em;
        left: -100%;
        background-color: var(--main-bg);
        text-align: left;
        padding: 0 2rem 2em;
        padding-top: 2rem;
        transition: left 0.5s ease-in-out;
        z-index: 999;
        a {
          font-size: 16px;
          margin-bottom: 22.17px;
          display: block;
        }
        &.opened {
          left: 0;
        }
      }

      .header-btns {
        display: grid;
        max-width: 8em;
        gap: 20px;
      }
    }
  }
}



.header-section-disclaimer {
  font-weight: 400;
  font-size: 0.6666666666666667em;
  line-height: 2.33333333333333;
  // height: 50px;
  width: 100%;
  background-color: #322020;
  color: var(--main-font);
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 2;
  box-sizing: border-box;
  padding: 0.626em 10px;
  text-align: center;
  top: 0;
  left: 0;
}