:root {
    --main-font: #ffffff;
    --main-bg: #58061c;
    --button-bg-col: #FEA23F;
  }

  /* FONTS */
  @font-face {
    font-family: 'Philosopher';
    src: url('/public/assets/fonts/Philosopher-Bold.woff2') format('woff2'),
        url('/public/assets/fonts/Philosopher-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Philosopher';
    src: url('/public/assets/fonts/Philosopher-Regular.woff2') format('woff2'),
        url('/public/assets/fonts/Philosopher-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans 7pt Condensed';
    src: url('/public/assets/fonts/NunitoSans7ptCondensed-Regular.woff2') format('woff2'),
        url('/public/assets/fonts/NunitoSans7ptCondensed-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Overlock';
    src: url('/public/assets/fonts/Overlock-Black.woff2') format('woff2'),
        url('/public/assets/fonts/Overlock-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Overlock';
    src: url('/public/assets/fonts/Overlock-Regular.woff2') format('woff2'),
        url('/public/assets/fonts/Overlock-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}



html {
    
    font-size: 13px;
}

body {
    margin: 0;
    font-family: "Philosopher", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--main-font);
    background-color: var(--main-bg);
}

@media (min-width: 376px) {
    html {
        /* font-size: calc([value-min] + ([value-max] - [value-min]) * ((100vw - [breakpoint-min]) / ([breakpoint-max] - [breakpoint-min]))); */
        font-size: calc(13px + (18 - 13) * ((100vw - 375px) / (1440 - 375)));
    }
}
/* p: 23.4000234000234 */
@media (min-width: 1600px) {
    html {
        font-size: calc(18px + 2 * ((100vw - 1600px) / 960));
    }
}

p {
    line-height: 1.333333333333333;
    font-weight: 400;
    font-size: 1em;
}

h1, .h1 {
    font-size: 2.30769230769230em;
    margin-bottom: 1.1rem;
    margin-top: 0;
    line-height: 1.125;
    font-weight: 700;
    
}

h2, .h2 {
    font-size: 2.222222222222222em;
    margin-bottom: 0.675em;
    font-weight: 700;
    font-family: Philosopher;
    line-height: 1.3;
    letter-spacing: 0.03em;
    margin-top: 0;
}

h3, .h3 {
    font-size: 1.888888888888889em;
    font-weight: 700;
}

h4, .h4 {
    font-size: 1.333333333333333em;
    font-weight: 700;
    margin-top: 0;
}

figure {
    margin: 0;
}

.default-btn {
    background: linear-gradient(180deg, #FDB73F 0%, #FEA03F 45.31%);
    font-size: 2em;
    padding: calc(0.3476em - 2px) 1.237em;
    display: inline-block;
    color: #fff;
    box-shadow: 0px 4px 4px 0px #00000040;
    text-decoration: none;
    border-radius: 0.5555555555555556em;
    text-shadow: 0px 4px 4px #00000040;
    border: 2px solid transparent;
    transition: all .4s ease-in-out;
    font-family: 'Philosopher';
    cursor: pointer;
}

.default-btn.red {
    background: linear-gradient(180deg, #C4431A 0%, #AF2742 100%);
    box-shadow: none;
    border-color: #c4431A;
}

.default-btn:hover {
    border-color: #FDB73F;
    background: transparent;
    color: #FDB73F;
}

@media (min-width: 600px) {
    h1, .h1 {
        font-size: 2.666666666666667em;
    }
}

@media (min-width: 1000px) {
    .mobile-only_1000 {
        display: none !important;
    }
}

@media (max-width: 1001px) {
    .desktop-only_1000 {
        display: none !important;
    }
}






/* Form Fields  */

input, textarea {
    font-size: 1em;
    width: -webkit-fill-available;
    border-radius: 0.8333333333333333em;
    padding: 0.7222222222222222em 1.611111111111111em;
    margin-bottom: 0.833333333333333em;
    font-weight: 400;
    font-family: "Nunito Sans 7pt Condensed";
    border: none;
}

input::placeholder, textarea::placeholder {
    color: #c4c4c4;
}

input::-moz-placeholder, textarea::-moz-placeholder {
    color: #c4c4c4;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #c4c4c4;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #c4c4c4;
}
  
textarea {
    border-radius: 1.388888888888889em;
}



.f-overlock {
    font-family: 'Overlock';
}


.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App > footer {
    margin-top: auto;
}

.legal-page p {
    font-size: 13px !important;
    line-height: 1.3125 !important;
}

@media (min-width: 600px) {
    .legal-page p {
        font-size: 16px !important;
        line-height: 1.3125 !important;
    }
}


.popupError {
    color: #001f3f;
    font-weight: 800;
    text-align: center;
    display: block;
    font-size: 13px;
}

.intl-tel-input .flag-container .arrow.down:after {
    color: #000;
}

.intl-tel-input .country-list {
    color: #000;
}