.main-footer {
  background-color: #471229;
  .footer-wrapper {
    padding: 1.777777777777778em 2em;
    @media (min-width: 830px) {
      padding: 1.777777777777778em 3.88888888888888em;
    }
    @media (min-width: 1700px) {
      max-width: 1440px;
      margin: 0 auto;
    }
    .footer-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2em;
      justify-content: space-between;
      align-items: center;
      @media (min-width: 921px) {
        grid-template-columns: 41.51111111111111em max-content;
      }
      @media (max-width: 920px) {
        & > .footer-col:first-of-type {
          order: 2;
        }
        .footer-logo {
          text-align: center;
        }
      }
      p {
        font-size: 0.8888888888888889rem;
        line-height: 1.375;

      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 2em;
        padding-top: 2em;
        flex-direction: column;
        @media (min-width: 650px) {
          flex-direction: row;
        }
        li {
            display: flex;
        }
        .seperator {
          display: none;
          margin-left: 2em;
          @media (min-width: 650px) {
            display: block;
          }
        }
        a {
          color: var(--main-font);
          text-decoration: none;
          // font-size: 0.8888888888888889em;
          font-weight: 400;
          // font-size: 1.125em;
          transition: all 0.4s ease-in-out;
          &:hover {
            text-shadow: 4px 4px 6px #f3f9ff47;
          }
        }
      }
    }
  }
}
