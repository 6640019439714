.contactPage {
  .bg-container {
    @media (max-width: 700px) {
        background-image: unset !important;
    }
    @media (min-width: 700px) {
      background-position-x: 20%;
    }
    @media (min-width: 1000px) {
      background-position-x: 0%;
    }
  }
}
