.gamesWrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: bottom;
  @media (max-width: 1000px) {
    background-image: none !important;
  }
  .gamesWrapper__inner {
    max-width: 84.93055555555556%;
    margin: 0 auto 0;
    padding-top: 3em;
    @media (min-width: 1700px) {
      max-width: 1440px;
    }
    .gamesGrid {
      display: grid;
      grid-auto-rows: 1fr;
      gap: 2em;
      padding-top: 2.388888888888889em;
      padding-bottom: 2.166666666666667em;
      padding-left: 3em;
      padding-right: 3em;
      @media (min-width: 537px) {
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 600px) {
        padding-top: 4.388888888888889em;
        grid-template-columns: repeat(3, 1fr);
      }
      @media (min-width: 1117px) {
        gap: 3em;
        grid-template-columns: repeat(5, 1fr);
      }
      img {
        width: 100%;
      }
      .single-game-col {
        p {
          font-size: 1.230769230769231em;
          @media (min-width: 600px) {
            font-size: 0.641025em;
          }
        }
      }
    }
  }
  .gameReg {
    cursor: pointer;
  }
  .single-game-col {
    position: relative;
    overflow: hidden;
    button {
      position: absolute;
      top: 0;
      left: 50%;
      font-size: 1.5em;
      transform: translate(-50%, -110%);
      min-width: 80%;
      padding-left: 0.5em;
      padding-right: 0.5em;
      pointer-events: none;
      transition: all .4s ease-in-out
    }
    &:hover {
      button {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
