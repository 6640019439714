.lp-bg-container {
  background-repeat: no-repeat;
  background-size: cover;
  .lp-wrapper {
    margin: 0 10.76388888888889%;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1700px) {
      margin: 0 auto;
      max-width: 1440px;
    }
    .lp-boxes-grid {
      display: flex;
      //   grid-template-columns: 1fr 1fr 1fr;
      gap: 5.787611%;
      row-gap: 3em;
      justify-content: center;
      align-items: end;
      flex-wrap: wrap;
      .box {
        // background: rgb(91 11 40 / 40%);
        padding: 1.5em 1.66666666666666em;
        border: 1px solid #ffffff;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 15px;
        position: relative;
        width: 18em;
        @media (min-width: 1000px) {
          max-width: 328px;
          flex: 1 1 20%;
        }

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(91, 11, 40, 0.4);
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 15px;
          z-index: 1;
          backdrop-filter: blur(3px);
        }
        & > * {
          position: relative;
          z-index: 2;
        }
        h2 {
          font-size: 1.7em;
          @media (min-width: 1413px) {
            font-size: 2em;
          }
        }
        p {
          font-size: 0.94017em;
          line-height: 1.136363636363636;
        }
        .number {
          //   img {
          //     position: absolute;
          //     right: 100%;
          //     top: 0;
          //   }
          font-size: 13.88888888888889em;
          color: rgba(91, 11, 40, 0.4);
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: #fff;
          position: absolute;
          font-weight: 700;
          z-index: 0;
        }

        &:first-of-type {
          .number {
            top: -5rem;
            right: 93%;
            @media (min-width: 1000px) {
              top: -6rem;
              right: 90%;
              z-index: 0;
            }
          }
        }
        &:nth-child(2) {
          .number {
            top: -4.5rem;
            right: 76%;
            @media (min-width: 1000px) {
              top: -1rem;
              right: 76%;
              z-index: 0;
            }
          }
        }
        &:nth-child(3) {
          .number {
            top: -4.5rem;
            right: 79%;

            @media (min-width: 1000px) {
              top: -5rem;
              right: 76%;
              z-index: 0;
            }
          }
        }
      }
    }
  }
}
