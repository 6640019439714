.LpKnight {
  .top-layer__img {
    img {
      left: unset;
      right: 0;
      height: calc(90% + 4.4em);

      @media (max-width: 700px) {
        position: absolute;
        right: -2.666666666666667em;
        bottom: 0;
        height: 100%;
      }
      @media (min-width: 817px) {
      }
      @media (min-width: 700px) {
        right: -2.666666666666667em;
        // right: unset;
        height: calc(100% + 2.4em);
      }
      @media (min-width: 1030px) {
        right: 3.666666666666667em;
        height: calc(100% + 5.4em);
      }
      @media (min-width: 1700px) {
        right: 10.583333vw;
      }
    }
  }
  .bg-container {
    
    @media (max-width: 842px) {
      background-size: cover;
      overflow: hidden;
      .wrapper {
        position: relative;
        z-index: 1;
        background-color: rgba($color: #000000, $alpha: 0.5);
      }
      .bg__text-container {
        background-color: transparent;
      }
    }

    @media (max-width: 842px) and (min-width: 700px) {
        .wrapper {
            padding-left: 3.8888888889em;
            margin: 0;
        }
    }
  }
}
