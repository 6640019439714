.LpPage {
  .gamesWrapper__inner {
    margin: 0 10.76388888888889%;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1700px) {
      margin: 0 auto;
    }
  }
}
