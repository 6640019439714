.login {
  margin: 0 auto;
  @media (min-width: 487px) {
    max-width: 75%;
  }
  .changePopup {
    font-family: "Overlock";
    font-weight: 400;
    font-size: 0.8888888888888889em;
    & > span {
      position: relative;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background-color: currentColor;
      }
    }
  }
  h2 {
    font-family: "Overlock";
  }
  .formField + .formField {
    margin-top: 1em;
  }
  .checkbox2 {
    label {
      &::after {
        background-color: #ffffff;
      }
    }
    // input:checked + label::after {
    //   background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjczNzEgMC4zODIwNzNDMTcuNjE3IDAuMjYxMDA3IDE3LjQ3NDEgMC4xNjQ5MTQgMTcuMzE2NyAwLjA5OTMzNzhDMTcuMTU5MyAwLjAzMzc2MTYgMTYuOTkwNSAwIDE2LjgyIDBDMTYuNjQ5NSAwIDE2LjQ4MDYgMC4wMzM3NjE2IDE2LjMyMzIgMC4wOTkzMzc4QzE2LjE2NTggMC4xNjQ5MTQgMTYuMDIzIDAuMjYxMDA3IDE1LjkwMjkgMC4zODIwNzNMNi4yNzk5OCAxMC4wMTc5TDIuMjM3MDcgNS45NjIwN0MyLjExMjM5IDUuODQxNjQgMS45NjUyMiA1Ljc0Njk0IDEuODAzOTUgNS42ODMzOUMxLjY0MjY4IDUuNjE5ODMgMS40NzA0NyA1LjU4ODY2IDEuMjk3MTUgNS41OTE2NkMxLjEyMzgzIDUuNTk0NjYgMC45NTI4MDQgNS42MzE3NyAwLjc5MzgyOCA1LjcwMDg2QzAuNjM0ODUxIDUuNzY5OTYgMC40OTEwNDEgNS44Njk2OSAwLjM3MDYwOCA1Ljk5NDM3QzAuMjUwMTc1IDYuMTE5MDQgMC4xNTU0NzggNi4yNjYyMSAwLjA5MTkyMjYgNi40Mjc0OEMwLjAyODM2NzUgNi41ODg3NSAtMC4wMDI4MDEwNCA2Ljc2MDk3IDAuMDAwMTk3NTIgNi45MzQyOEMwLjAwMzE5NjA4IDcuMTA3NiAwLjA0MDMwMjggNy4yNzg2MyAwLjEwOTM5OSA3LjQzNzZDMC4xNzg0OTQgNy41OTY1OCAwLjI3ODIyNiA3Ljc0MDM5IDAuNDAyOSA3Ljg2MDgyTDUuMzYyOSAxMi44MjA4QzUuNDgyOTggMTIuOTQxOSA1LjYyNTg0IDEzLjAzOCA1Ljc4MzI0IDEzLjEwMzZDNS45NDA2NCAxMy4xNjkxIDYuMTA5NDcgMTMuMjAyOSA2LjI3OTk4IDEzLjIwMjlDNi40NTA1IDEzLjIwMjkgNi42MTkzMyAxMy4xNjkxIDYuNzc2NzMgMTMuMTAzNkM2LjkzNDEzIDEzLjAzOCA3LjA3Njk5IDEyLjk0MTkgNy4xOTcwNyAxMi44MjA4TDE3LjczNzEgMi4yODA4MkMxNy44NjgyIDIuMTU5ODcgMTcuOTcyOCAyLjAxMzA3IDE4LjA0NDQgMS44NDk2N0MxOC4xMTU5IDEuNjg2MjggMTguMTUyOSAxLjUwOTgzIDE4LjE1MjkgMS4zMzE0NUMxOC4xNTI5IDEuMTUzMDcgMTguMTE1OSAwLjk3NjYxOCAxOC4wNDQ0IDAuODEzMjIzQzE3Ljk3MjggMC42NDk4MjggMTcuODY4MiAwLjUwMzAyNyAxNy43MzcxIDAuMzgyMDczWiIgZmlsbD0iIzg1ODU4NSIvPgo8L3N2Zz4K')
    //   // background-repeat: no-repeat;
    // }
  }

  .formField {
    .intl-tel-input {
      width: 100%;
      .selected-flag {
        margin-left: 0.5em;
      }
    }
  }
}

.skip-play__grid {
  display: grid;
  grid-template-columns: max-content 1.277777777777778em max-content;
  gap: 1.5em;
  align-items: center;
  justify-content: center;
  span {
    font-size: 1.333333333333333em;
    font-weight: 700;
  }
}