.gridWrapper {
  padding: 5em 2em 9em;
  @media (max-width: 820px) {
    padding-bottom: 4em;
  }
  @media (min-width: 700px) {
    margin-left: 3.8888888889em;
  }
  @media (min-width: 1100px) {
    margin-left: 9.7222222222%;
    margin-right: 3.195%;
  }
  @media (min-width: 1700px) {
    max-width: 1340px;
    margin: 0 auto;
  }
  .gridWrapper__grid {
    @media (min-width: 1000px) {
      display: grid;
      grid-template-columns: 20.77777777777778em 1fr;
      gap: 6.3846153846153%;
      align-items: center;
    }
    @media (min-width: 1100px) {
      gap: 10.3846153846153%;
    }
    @media (min-width: 1300px) {
      grid-template-columns: 27.77777777777778em 1fr;
    }
    .image {
      figure {
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        img {
          //   height: 100%;
          width: 100%;
          //   object-fit: cover;
        }
      }
    }
    figure {
      @media (max-width: 1000px) {
        text-align: center;
        max-width: 100%;
        margin: 1.5em 0;
        img {
            max-width: 100%;
        }
      }
    }
  }
}
