.AboutUs {
    overflow: hidden;
  .top-layer__img {
    @media (max-width: 911px) {
        overflow: hidden;
    }
    img {
      height: auto;
      left: unset;
      right: unset;
      @media (min-width: 912px) {
        left: unset;
        right: -18.680555555555556%;
      }
      @media (min-width: 1340px) {
        right: 0;
      }
      @media (min-width: 1340px) {
        right: 8.680555555555556%;
      }
      @media (max-width: 911px) {
        position: relative;
        margin-top: 0;
      }
      @media (max-width: 639px) {
        margin-right: -12em;
        max-height: 30em;
      }
    }
  }
  .bg__text-container {
    padding-left: 1.555555555555556em;
    padding-right: 1.333333333333333em;
    max-width: 30.388889em;
    @media (max-width: 911px) {
      background: transparent;
      max-width: 100%;
    }
  }
  .bg-container {
    background-size: cover;
    @media (max-width: 911px) {
      background-size: auto 33.69230769230769em;
      background-position: bottom center;
    }
  }
  .wrapper {
    @media (max-width: 911px) {
      margin: 0;
    }
  }
}
