.GoBackBtn {
  background: transparent;
  border: none;
  color: #fea23f;
  font-family: "Philosopher";
  font-size: 1.33333333333333em;
  line-height: 1.291666666666667;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 1.583333333333333em;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  svg {
    overflow: visible;
    path {
        transition: all .4s ease-in-out;
    }
  }
  &:hover {
    color: #fff;
    svg {
      path {
        fill: currentColor;
        transform: translateX(-5px);
      }
    }
  }
}
