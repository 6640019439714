.LpGrid {
  margin: 0 10.76388888888889%;
  padding-left: 0;
  padding-right: 0;
  @media (min-width: 1700px) {
    margin: 0 auto;
  }
  .gridWrapper__grid {
    grid-template-columns: 1fr 22.94444444444444em;
    gap: 10.436548%;
    @media (max-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr;
        .grid-col:not(.image) {
            order: 2;
        }
    }
  }
}
