.popupContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: #000000a1;
    display: flex;
    align-items: center;
    justify-content: center;
    .popup_inner {
        max-width: 73%;
        width: 100%;
        background-color: #E04A41;
        border-radius: 0.5555555555555556em;
        padding: 2.611111111111111em 2.055555555555556em;
        text-align: center;
        position: relative;
        @media (min-width: 800px) {
            width: 41.25%;
            
            max-width: 594px;
            padding: 2.611111111111111em 4.055555555555556em;
        }
        @media (max-height: 768px) and (max-width: 700px) {
            // margin-top: -10em;
        }
        .checkbox-cover {
            font-family: 'Overlock';
            font-weight: 400;
            font-size: 1.111111111111111em;
            position: relative;
            a {
                text-decoration: none;
                color: #fff;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    left: 0;
                    bottom: -1px;
                }
            }
            input {
                height: 0;
                left: 0;
                position: absolute;
                top: 0;
                visibility: hidden;
                width: 0;
            }
            label {
                position: relative;
                max-width: fit-content;
                display: block;
                padding-left: 2em;
                margin: 0 auto 1em;
                &::after {
                    border: 2px solid;
                    content: "";
                    cursor: pointer;
                    display: block;
                    height: 1.2em;
                    width: 1.2em;
                    background-repeat: no-repeat;
                    background-position: center;
                    border-radius: 4px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
            input:checked + label::after {
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjcxMDQgMS4yMTAxQzE0LjYxNzUgMS4xMTYzOCAxNC41MDY5IDEuMDQxOTggMTQuMzg1IDAuOTkxMjEzQzE0LjI2MzIgMC45NDA0NDUgMTQuMTMyNCAwLjkxNDMwNyAxNC4wMDA0IDAuOTE0MzA3QzEzLjg2ODQgMC45MTQzMDcgMTMuNzM3NyAwLjk0MDQ0NSAxMy42MTU5IDAuOTkxMjEzQzEzLjQ5NCAxLjA0MTk4IDEzLjM4MzQgMS4xMTYzOCAxMy4yOTA0IDEuMjEwMUw1Ljg0MDQ0IDguNjcwMUwyLjcxMDQ0IDUuNTMwMUMyLjYxMzkyIDUuNDM2ODcgMi40OTk5OCA1LjM2MzU1IDIuMzc1MTIgNS4zMTQzNUMyLjI1MDI2IDUuMjY1MTQgMi4xMTY5NCA1LjI0MTAxIDEuOTgyNzYgNS4yNDMzNEMxLjg0ODU4IDUuMjQ1NjYgMS43MTYxNyA1LjI3NDM4IDEuNTkzMDkgNS4zMjc4OEMxLjQ3MDAxIDUuMzgxMzcgMS4zNTg2OCA1LjQ1ODU4IDEuMjY1NDQgNS41NTUxMUMxLjE3MjIgNS42NTE2MyAxLjA5ODg5IDUuNzY1NTcgMS4wNDk2OCA1Ljg5MDQyQzEuMDAwNDggNi4wMTUyOCAwLjk3NjM0NyA2LjE0ODYgMC45Nzg2NjkgNi4yODI3OEMwLjk4MDk5IDYuNDE2OTYgMS4wMDk3MiA2LjU0OTM3IDEuMDYzMjEgNi42NzI0NUMxLjExNjcgNi43OTU1MyAxLjE5MzkyIDYuOTA2ODcgMS4yOTA0NCA3LjAwMDFMNS4xMzA0NCAxMC44NDAxQzUuMjIzNCAxMC45MzM4IDUuMzM0IDExLjAwODIgNS40NTU4NiAxMS4wNTlDNS41Nzc3MiAxMS4xMDk4IDUuNzA4NDMgMTEuMTM1OSA1Ljg0MDQ0IDExLjEzNTlDNS45NzI0NSAxMS4xMzU5IDYuMTAzMTYgMTEuMTA5OCA2LjIyNTAyIDExLjA1OUM2LjM0Njg3IDExLjAwODIgNi40NTc0OCAxMC45MzM4IDYuNTUwNDQgMTAuODQwMUwxNC43MTA0IDIuNjgwMTFDMTQuODExOSAyLjU4NjQ2IDE0Ljg5MyAyLjQ3MjgxIDE0Ljk0ODQgMi4zNDYzMUMxNS4wMDM4IDIuMjE5ODEgMTUuMDMyNCAyLjA4MzIxIDE1LjAzMjQgMS45NDUxMUMxNS4wMzI0IDEuODA3IDE1LjAwMzggMS42NzA0IDE0Ljk0ODQgMS41NDM5QzE0Ljg5MyAxLjQxNzQgMTQuODExOSAxLjMwMzc1IDE0LjcxMDQgMS4yMTAxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==')
                // background-repeat: no-repeat;
            }
            &.checkbox2 {
                text-align: left;
                label {
                    margin-left: 0;
                    font-size: 0.75em;
                }
                input:checked + label::after {
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjczNzEgMC4zODIwNzNDMTcuNjE3IDAuMjYxMDA3IDE3LjQ3NDEgMC4xNjQ5MTQgMTcuMzE2NyAwLjA5OTMzNzhDMTcuMTU5MyAwLjAzMzc2MTYgMTYuOTkwNSAwIDE2LjgyIDBDMTYuNjQ5NSAwIDE2LjQ4MDYgMC4wMzM3NjE2IDE2LjMyMzIgMC4wOTkzMzc4QzE2LjE2NTggMC4xNjQ5MTQgMTYuMDIzIDAuMjYxMDA3IDE1LjkwMjkgMC4zODIwNzNMNi4yNzk5OCAxMC4wMTc5TDIuMjM3MDcgNS45NjIwN0MyLjExMjM5IDUuODQxNjQgMS45NjUyMiA1Ljc0Njk0IDEuODAzOTUgNS42ODMzOUMxLjY0MjY4IDUuNjE5ODMgMS40NzA0NyA1LjU4ODY2IDEuMjk3MTUgNS41OTE2NkMxLjEyMzgzIDUuNTk0NjYgMC45NTI4MDQgNS42MzE3NyAwLjc5MzgyOCA1LjcwMDg2QzAuNjM0ODUxIDUuNzY5OTYgMC40OTEwNDEgNS44Njk2OSAwLjM3MDYwOCA1Ljk5NDM3QzAuMjUwMTc1IDYuMTE5MDQgMC4xNTU0NzggNi4yNjYyMSAwLjA5MTkyMjYgNi40Mjc0OEMwLjAyODM2NzUgNi41ODg3NSAtMC4wMDI4MDEwNCA2Ljc2MDk3IDAuMDAwMTk3NTIgNi45MzQyOEMwLjAwMzE5NjA4IDcuMTA3NiAwLjA0MDMwMjggNy4yNzg2MyAwLjEwOTM5OSA3LjQzNzZDMC4xNzg0OTQgNy41OTY1OCAwLjI3ODIyNiA3Ljc0MDM5IDAuNDAyOSA3Ljg2MDgyTDUuMzYyOSAxMi44MjA4QzUuNDgyOTggMTIuOTQxOSA1LjYyNTg0IDEzLjAzOCA1Ljc4MzI0IDEzLjEwMzZDNS45NDA2NCAxMy4xNjkxIDYuMTA5NDcgMTMuMjAyOSA2LjI3OTk4IDEzLjIwMjlDNi40NTA1IDEzLjIwMjkgNi42MTkzMyAxMy4xNjkxIDYuNzc2NzMgMTMuMTAzNkM2LjkzNDEzIDEzLjAzOCA3LjA3Njk5IDEyLjk0MTkgNy4xOTcwNyAxMi44MjA4TDE3LjczNzEgMi4yODA4MkMxNy44NjgyIDIuMTU5ODcgMTcuOTcyOCAyLjAxMzA3IDE4LjA0NDQgMS44NDk2N0MxOC4xMTU5IDEuNjg2MjggMTguMTUyOSAxLjUwOTgzIDE4LjE1MjkgMS4zMzE0NUMxOC4xNTI5IDEuMTUzMDcgMTguMTE1OSAwLjk3NjYxOCAxOC4wNDQ0IDAuODEzMjIzQzE3Ljk3MjggMC42NDk4MjggMTcuODY4MiAwLjUwMzAyNyAxNy43MzcxIDAuMzgyMDczWiIgZmlsbD0iIzg1ODU4NSIvPgo8L3N2Zz4K')
                    // background-repeat: no-repeat;
                }
            }
        }

        .buttons {
            margin-top: 2.5em;
            > * {
                margin-right: 2em;
                @media (max-width: 600px) {
                    margin-right: 1em;
                }
                
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .closeBtn {
            position: absolute;
            top: 8px;
            right: 12px;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
        @media (max-width: 600px) {
            h2 {
                br {
                    display: none;
                }
            }
        }
    }
    &:not(.centered) {
        
        max-width: 100%;
        width: 23.83333333333333em;
        height: max-content;
        top: unset;
        bottom: 0;
        background-color: transparent;
        @media (max-height: 915px) {
            z-index: 998;
        }
        @media (min-width: 1130px) {
            max-width: 431px;
            width: 100%;
        }
        .popup_inner {
            max-width: 100%;
            width: 100%;
            text-align: left;
            padding-top: 1.722222222222222em;
            padding-bottom: 1.388888888888889em;
            padding-right: 3.555555555555556em;
            @media (max-height: 866px) and (min-width: 700px) and (max-width: 1606px) {
                // margin-top: -10em;
                border: 1px solid;
            }
        }
    }
    .default-btn {
        font-size: 1.333333333333333em;
    }
}