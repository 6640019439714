.bg-container {
  // padding: 0 7.777777777777778em;

  background-repeat: no-repeat;
  background-size: auto 22.69230769230769em;
  background-position: bottom right;
  position: relative;
  @media (min-width: 700px) {
    background-size: cover;
    background-position-y: center;
    background-position-x: right;
  }

  .wrapper {
    @media (min-width: 700px) {
      margin-left: 3.8888888889em;
    }
    @media (min-width: 1100px) {
        margin-left: 9.722222222222222%;
    }
    @media (min-width: 1500px) {
      margin-left: 7.777777777777778em;
    }
    @media (min-width: 1700px) {
      max-width: 1340px;
      margin: 0 auto;
    }
  }

  .bg__text-container {
    @media (min-width: 700px) {
      max-width: 25.388889em;
      background-color: rgba($color: #000000, $alpha: 0.4);
    }

    padding: 2.5em 2em 3.5em;
  }

  .top-layer__img {
    img {
      display: block;
      margin: -2em 0 0 auto;
      height: 24.692308em;
      @media (min-width: 700px) {
        position: absolute;
        bottom: 0;
        right: 0;
        height: calc(100% + 30px);
      }
      @media (min-width: 886px) {
        left: 50.9861111111111%;
        right: unset;
      }
      @media (min-width: 1200px) {
        left: 40.9861111111111%;
      }
    //   @media (min-width: 886px) {
    //     left: 50.9861111111111%;
    //     right: unset;
    //   }
      @media (min-width: 1700px) {
        left: 50.9861111111111%;
      }
    }
  }
}
