.PopupCookie {
    h4 {
        font-size: 1.555555555555556em;
        margin-bottom: 0.7142857142857143em;
        
    }
    p {
        font-size: 0.8888888888888889em;
    }
    .cookiesLink {
        font-size: 1.333333333333333em;
        display: inline-block;
        margin-top: 1em;
        color: #fff;
        text-decoration: none;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            bottom: -2px;
            left: 0;
        }
    }
    .default-btn {
        font-size: 1.333333333333333em;
    }
}