.ContactForm {
  .title {
    font-family: "Overlock";
    font-weight: 700;
    @media (min-width: 700px) {
      text-align: center;
    }
  }
  input,
  textarea {
    font-size: 1em;
    width: -webkit-fill-available;
    border-radius: 0.8333333333333333em;
    padding: 0.7222222222222222em 1.611111111111111em;
    margin-bottom: 0.833333333333333em;
    font-weight: 400;
    font-family: "Nunito Sans 7pt Condensed";
    border: none;

    &::placeholder {
      color: #c4c4c4;
    }
    &::-moz-placeholder {
      color: #c4c4c4;
    }
    &:-ms-input-placeholder {
      color: #c4c4c4;
    }
    &::-webkit-input-placeholder {
      color: #c4c4c4;
    }
  }
  textarea {
    border-radius: 1.388888888888889em;
  }
  .default-btn {
    display: block;
    @media (min-width: 700px) {
        margin: 1.33333333333333em auto 0.5em;
    }
    
  }
}

#name_error,
#email_error {
  top: 60px;
}
#message_error {
  bottom: 10px;
}
.error_message-show {
  display: flex;
  //   position: absolute;
  z-index: 2;
  width: 100%;
  left: 15px;
  color: var(--error);
  font-weight: 700;
  font-size: 0.7777777777777778em;
  margin-bottom: 1em;
}
.error_message-hide {
  display: none;
}
.contact-form-cover .hide-form {
  display: none;
}
.show-success-message {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: var(--main-font);
  text-align: center;
}
.hide-success-message {
  display: none;
}
