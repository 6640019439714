.notFoundWrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
    background-position-y: bottom;
    .notFoundWrapper__inner {
      max-width: 84.93055555555556%;
      margin: 0 auto 0;
      padding-top: 11.66666666666667em;
      padding-bottom: 8.72222222222222em;
      text-align: center;
      @media (min-width: 1700px) {
        max-width: 1440px;
      }
      .large {
        font-size: 5.555555555555556em;
        margin: 0;
      }
    }
}