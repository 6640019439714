.AboutUsHome {
  .top-layer__img {
    img {
      left: unset;
      right: 0;
      height: calc(90% + 4.4em);
      
      @media (max-width: 700px) {
        max-height: 30em;
        margin-top: -5em;
      }
      @media (min-width: 817px) {
        height: calc(100% + 5.4em);
      }
      @media (min-width: 900px) {
        right: 3.666666666666667em;
        // right: unset;
      }
      @media (min-width: 1700px) {
        right: 10.583333vw;
      }
    }
  }
}
